import React, {useEffect, useMemo, useRef} from 'react';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Constants from "../../../utils/constants";
import './PatrimoineMap.css'
import {helper} from "../../../utils/helper";
import {Icon, divIcon} from 'leaflet';

function PatrimoineMap({patrimoine, conteneurs, onConteneurClick, ...props}) {
    const [conteneursData, setConteneurData] = React.useState(null)
    const [expandedMarkers, setExpandedMarkers] = React.useState({});
    const [center, setCenter] = React.useState([0.0, 0.0]);
    const mapRef = React.useRef(null);

    const calculateCenter = (conteneurs) => {
        const totalLat = conteneurs.reduce((acc, c) => acc + parseFloat(c.latitude), 0);
        const totalLng = conteneurs.reduce((acc, c) => acc + parseFloat(c.longitude), 0);
        const avgLat = totalLat / conteneurs.length;
        const avgLng = totalLng / conteneurs.length;
        return [avgLat, avgLng];
    };

    useEffect(() => {
        if (conteneurs && conteneurs.length > 0) {
            const newCenter = calculateCenter(conteneurs);
            setCenter(newCenter);
        } else if (patrimoine.isUnipatrimoine) {
            setCenter([patrimoine.latitude, patrimoine.longitude])
        }
    }, [conteneurs]);

    useEffect(() => {
        if (mapRef.current) {
            const mapInstance = mapRef.current;
            mapInstance.setView(center, 13);
        }
    }, [mapRef.current, center]);

    useEffect(() => {
        if (conteneurs) {
            let data = {}
            let len = conteneurs.length;
            while (len--) {
                let key = conteneurs[len].latitude + "/" + conteneurs[len].longitude
                if (data[key]) {
                    data[key].push(conteneurs[len])
                } else {
                    data[key] = [conteneurs[len]]
                }
            }

            setConteneurData(data)
        }
    }, [conteneurs])

    // const center = useMemo(() => [47.3220, 5.0415], []);

    const getAverageConteneurStatusIcon = (conteneurs) => {

        // Si le tableau est vide, renvoyer l'icône par défaut
        if (!conteneurs.length) {
            return helper.getConteneurStatusIcon(1);
        }

        // Sinon, déterminer le status le plus important
        let highestStatus = conteneurs.reduce((highest, c) => Math.max(highest, c.status || 0), 0);

        // Renvoyer l'icône correspondant au status le plus important
        return helper.getConteneurStatusIcon(highestStatus);
    }

    const getConteneurStatusIcon = (c) => {
        return helper.getConteneurStatusIcon(c.status)
    }

    function getIcon(c, count) {
        let imgSrc = getAverageConteneurStatusIcon(c);
        return divIcon({
            className: 'my-div-icon',
            html: `<img class="marker-image" src="${imgSrc}"/>` +
                `<span class="marker-number-div">${count}</span>`,
            iconSize: [(56 / 1.5), (76 / 1.5)]
        });
    }

    const onMarkerClick = (c) => {
        const key = c.latitude + "/" + c.longitude;
        const numMarkers = conteneursData[key].length;

        // Si le nombre de marqueurs est 1, alors on ne fait rien
        if (numMarkers > 1) {
            setExpandedMarkers(prevExpanded => {
                if (prevExpanded[key]) {
                    const newExpanded = {...prevExpanded};
                    delete newExpanded[key];
                    return newExpanded;
                } else {
                    return {...prevExpanded, [key]: true};
                }
            });
        }

        onConteneurClick(conteneursData[key])
    }

    return (
        <MapContainer
            ref={mapRef}
            center={center}
            zoom={13}
            style={{height: "85vh", width: "100%"}}>
            <TileLayer
                url='https://{s}.{base}.maps.ls.hereapi.com/maptile/2.1/{type}/{mapID}/{variant}/{z}/{x}/{y}/256/{format}?apiKey={apiKey}&lg={language}'
                attribution='Map &copy; 1987-2021 <a href="http://developer.here.com">HERE</a>'
                subdomains='1234'
                mapID='newest'
                type='maptile'
                language='eng'
                format='png8'
                variant='normal.day'
                base='base'
                apiKey={Constants.HERE_MAPS_APIKEY}
                maxZoom={19}
            />
            {
                conteneursData &&
                Object.keys(conteneursData).map((key, i) => {
                    return conteneursData[key].map((c, j) => {
                        const baseLat = parseFloat(c.latitude);
                        const baseLng = parseFloat(c.longitude);
                        const isExpanded = expandedMarkers[key];
                        const numMarkers = conteneursData[key].length;

                        let lat = baseLat;
                        let lng = baseLng;

                        if (isExpanded) {
                            // calculer l'angle en radians
                            const angle = 2 * Math.PI * j / numMarkers;

                            // convertir les coordonnées polaires en coordonnées géographiques
                            lat = baseLat + Constants.MAP_RADIUS * Math.cos(angle);
                            lng = baseLng + Constants.MAP_RADIUS * Math.sin(angle) / Math.cos(baseLat / Constants.DEGREES_PER_RADIAN);
                        }

                        return (
                            <Marker
                                key={`${i}-${j}-${lat}-${lng}`}
                                position={[lat, lng]}
                                icon={getIcon(conteneursData[key], conteneursData[key][0]?.quantite)}
                                eventHandlers={{
                                    click: () => {
                                        onMarkerClick(c);
                                    },
                                }}
                            />
                        )
                    })
                })
            }
            {patrimoine.isUnipatrimoine &&
                <Marker
                    icon={new Icon({
                        iconUrl: getConteneurStatusIcon(patrimoine),
                        iconSize: [(56 / 1.5), (76 / 1.5)],
                    })}
                    position={[patrimoine.latitude, patrimoine.longitude]}/>
            }
        </MapContainer>
    )
}

export default PatrimoineMap;
