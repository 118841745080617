import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import ButtonCreate from "./layout/ButtonCreate";
import Constants from "../../utils/constants";
import LaveusesTable from "./laveuse/LaveusesTable";
import {laveusesActions} from "../../modules/laveuses/laveuses.actions";
import {LocalShipping} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";

function LaveusesPage({dispatch, ...props}) {

    useEffect(() => {
        if (!props.laveuses && !props.loading && !props.error) {
            dispatch(laveusesActions.fetchLaveuses())
        }
    }, [props.laveuses, props.loading, props.error]);

    useEffect(() => {
        return () => {
            // dispatch(conteneursActions.clearLaveuses());
        };
    }, [dispatch]);

    const handleDelete = (laveuse) => {
        dispatch(laveusesActions.deleteLaveuse(laveuse.id));
    }

    return (
        <>
            <PageTitle title={"Gestion des laveuses"} icon={<LocalShipping/>}/>
            <ButtonCreate route={Constants.APP_ROUTE_ADMIN_LAVEUSES_NEW} label="Ajouter une laveuse"/>
            {props.loading &&
                <Loader />
            }
            {!props.loading &&
                <LaveusesTable laveuses={props.laveuses} onDelete={handleDelete}/>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        laveuses: state.laveuses.laveuses,
        loading: state.laveuses.loading,
        error: state.laveuses.error
    }
}

export default connect(mapStateToProps)(LaveusesPage);
