import history from "../../utils/history";
import Constants from "../../utils/constants";
import {toastHelper} from "../../utils/toast-helper";
import {patrimoinesService} from "./patrimoines.service";
import {patrimoinesConstants} from "./patrimoines.constants";

export const patrimoinesActions = {
    fetchPatrimoine,
    fetchPatrimoineWithInterventions,
    fetchPatrimoinesForClient,
    createPatrimoine,
    updatePatrimoine,
    deletePatrimoine,
}

function fetchPatrimoine(id) {
    return dispatch => {
        dispatch(request());

        patrimoinesService.fetchPatrimoine(id)
            .then(
                (patrimoine) => {
                    dispatch(success(patrimoine));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: patrimoinesConstants.PATRIMOINE_FETCH_REQUEST}
    }

    function success(patrimoine) {
        return {type: patrimoinesConstants.PATRIMOINE_FETCH_SUCCESS, patrimoine}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: patrimoinesConstants.PATRIMOINE_FETCH_FAILURE, error}
    }
}

function fetchPatrimoineWithInterventions(id) {
    return dispatch => {
        dispatch(request());

        patrimoinesService.fetchPatrimoineWithInterventions(id)
            .then(
                (patrimoine) => {
                    dispatch(success(patrimoine));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: patrimoinesConstants.PATRIMOINE_FETCH_REQUEST}
    }

    function success(patrimoine) {
        return {type: patrimoinesConstants.PATRIMOINE_FETCH_SUCCESS, patrimoine}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: patrimoinesConstants.PATRIMOINE_FETCH_FAILURE, error}
    }
}

function fetchPatrimoinesForClient(idClient) {
    return dispatch => {
        dispatch(request());

        patrimoinesService.fetchPatrimoinesForClient(idClient)
            .then(
                (patrimoines) => {
                    dispatch(success(patrimoines));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: patrimoinesConstants.PATRIMOINES_FETCH_REQUEST}
    }

    function success(patrimoines) {
        return {type: patrimoinesConstants.PATRIMOINES_FETCH_SUCCESS, patrimoines}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: patrimoinesConstants.PATRIMOINE_FETCH_FAILURE, error}
    }
}

function createPatrimoine(values) {
    return dispatch => {
        dispatch(request());

        patrimoinesService.createPatrimoine(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_PATRIMOINE);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: patrimoinesConstants.PATRIMOINE_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le patrimoine a bien été créé.")
        return {type: patrimoinesConstants.PATRIMOINE_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: patrimoinesConstants.PATRIMOINE_CREATE_FAILURE, error}
    }
}

function updatePatrimoine(values, id, callback = null) {
    return dispatch => {
        dispatch(request());

        patrimoinesService.updatePatrimoine(values, id)
            .then(
                () => {
                    dispatch(success());
                    if (callback) {
                        callback()
                    } else {
                        history.replace(Constants.APP_ROUTE_PATRIMOINE);
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: patrimoinesConstants.PATRIMOINE_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le patrimoine a bien été mis à jour.")
        return {type: patrimoinesConstants.PATRIMOINE_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: patrimoinesConstants.PATRIMOINE_UPDATE_FAILURE, error}
    }
}
function deletePatrimoine(id) {
    return dispatch => {
        dispatch(request());

        patrimoinesService.deletePatrimoine(id)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_PATRIMOINE);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: patrimoinesConstants.PATRIMOINE_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le patrimoine a bien été supprimé.")
        return {type: patrimoinesConstants.PATRIMOINE_DELETE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: patrimoinesConstants.PATRIMOINE_DELETE_FAILURE, error}
    }
}