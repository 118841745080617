const APIURL = import.meta.env.VITE_APIURL
const API_ASSETS_URL = import.meta.env.VITE_APIASSETSURL
console.log(APIURL);
const Constants = {
    /** API ROUTE */
    API_ROUTE_LOGIN: APIURL + "/login_check",
    API_ROUTE_TOKEN_REFRESH: APIURL + "/refresh",
    API_ROUTE_ASK_RESET_PASSWORD: APIURL + "/users/ask-reset-password ",
    API_ROUTE_RESET_PASSWORD: APIURL + "/users/reset-password",
    API_ROUTE_USERS: APIURL + "/users",
    API_ROUTE_USER: APIURL + "/users/:id",
    API_ROUTE_EXPLOITANTS: APIURL + "/exploitants",
    API_ROUTE_EXPLOITANT: APIURL + "/exploitants/:id",
    API_ROUTE_ADMINS: APIURL + "/admins",
    API_ROUTE_ADMIN: APIURL + "/admins/:id",
    API_ROUTE_CLIENTS: APIURL + "/clients",
    API_ROUTE_CLIENT: APIURL + "/clients/:id",
    API_ROUTE_OPERATEURS: APIURL + "/operateurs",
    API_ROUTE_OPERATEUR: APIURL + "/operateurs/:id",
    API_ROUTE_USERS_LOGO: APIURL + "/user_logos",
    API_ROUTE_PATRIMOINES: APIURL + "/patrimoines",
    API_ROUTE_PATRIMOINE: APIURL + "/patrimoines/:id",
    API_ROUTE_PATRIMOINES_CLIENT: APIURL + "/patrimoines?client=:id",
    API_ROUTE_PATRIMOINE_INTERVENTIONS: APIURL + "/patrimoines/:id?groups=patrimoineintervention",
    API_ROUTE_PROBLEMS: APIURL + '/problems',
    API_ROUTE_PROBLEM: APIURL + '/problems/:id',
    API_ROUTE_FORMS: APIURL + '/forms',
    API_ROUTE_FORM: APIURL + '/forms/:id',
    API_ROUTE_LAVEUSES: APIURL + '/laveuses',
    API_ROUTE_LAVEUSE: APIURL + '/laveuses/:id',
    API_ROUTE_CONTENEURS: APIURL + '/conteneurs',
    API_ROUTE_CONTENEUR: APIURL + '/conteneurs/:id',
    API_ROUTE_INTERVENTIONS: APIURL + '/interventions',
    API_ROUTE_INTERVENTIONS_BY_CONTAINERID: APIURL + '/interventions?containerId=:id',
    API_ROUTE_INTERVENTIONS_BY_PATRIMOINEID: APIURL + '/patrimoine/:id/interventions',
    API_ROUTE_INTERVENTION: APIURL + '/interventions/:id',
    API_ROUTE_CAMPAIGNS: APIURL + '/campagne_lavages',
    API_ROUTE_CAMPAIGN: APIURL + '/campagne_lavages/:id',
    API_ROUTE_SYNTHESE: APIURL + '/synthese',
    API_ROUTE_SYNTHESE_INTERVENTION: APIURL + '/synthese/intervention/:id',
    API_ROUTE_SYNTHESE_INTERVENTION_TOKEN: "95735835-5def-47f5-94bf-912e8d4620f2",
    API_ROUTE_DASHBOARD_DATA: APIURL + '/dashboard',


    API_ASSETS_USER_LOGO: API_ASSETS_URL,
    API_ASSETS_PDF: API_ASSETS_URL,
    API_ASSETS_PHOTO_INTERVENTION: API_ASSETS_URL,


    /** APP ROUTE */
    APP_ROUTE_LOGIN: "/login",
    APP_ROUTE_FORGOT_PASSWORD: "/forgotPassword",
    APP_ROUTE_RESET_PASSWORD: "/resetPassword/:token",
    APP_ROUTE_HOME: "/",
    APP_ROUTE_ADMIN_USERS: "/admin/users",
    APP_ROUTE_ADMIN_USERS_NEW: "/admin/users/new",
    APP_ROUTE_ADMIN_USERS_EDIT: "/admin/users/:type/:id/edit",
    APP_ROUTE_PATRIMOINE: "/patrimoine",
    APP_ROUTE_PATRIMOINE_NEW: "/patrimoine/:id/new/",
    APP_ROUTE_PATRIMOINE_EDIT: "/patrimoine/:patrimoineId/edit",
    APP_ROUTE_PATRIMOINE_IMPORT: "/patrimoine/:id/import/",
    APP_ROUTE_PATRIMOINE_DETAIL: "/patrimoine/:id/detail/",
    APP_ROUTE_INTERVENTIONS: "/conteneurs/:id/interventions",
    APP_ROUTE_INTERVENTIONS_UNIPATRIMOINE: "/patrimoine/:id/interventions",
    APP_ROUTE_ADMIN_PROBLEMS: "/admin/problems",
    APP_ROUTE_ADMIN_PROBLEMS_NEW: "/admin/problems/new",
    APP_ROUTE_ADMIN_PROBLEMS_EDIT: "/admin/problems/:id/edit",
    APP_ROUTE_ADMIN_FORMS: "/admin/forms",
    APP_ROUTE_ADMIN_FORMS_NEW: "/admin/forms/new",
    APP_ROUTE_ADMIN_FORMS_EDIT: "/admin/forms/:id/edit",
    APP_ROUTE_ADMIN_LAVEUSES: "/admin/laveuses",
    APP_ROUTE_ADMIN_LAVEUSES_NEW: "/admin/laveuses/new",
    APP_ROUTE_ADMIN_LAVEUSES_EDIT: "/admin/laveuses/:id/edit",
    APP_ROUTE_ADMIN_CAMPAIGNS: "/admin/tournees",
    APP_ROUTE_ADMIN_CAMPAIGNS_NEW: "/admin/tournees/new",
    APP_ROUTE_ADMIN_CAMPAIGNS_EDIT: "/admin/tournees/:id/edit",
    APP_ROUTE_ADMIN_CAMPAIGNS_UPDATE: "/admin/tournees/:id/update",
    APP_ROUTE_ADMIN_INTERVENTIONS_FORM: "/admin/intervention/form",
    APP_ROUTE_SYNTHESE: "/synthese",
    APP_ROUTE_DASHBOARD: "/dashboard",

    /** DIVERS */
    MAX_FILESIZE_UPLOAD: 2 * 100000, // 2 MO
    GOOGLEMAPS_API_KEY: 'AIzaSyCGTI7RjTZ50rdshWTSgCAlZo9inXLCb9w',

    /** DATA EN DUR */
    CONTENEURS_TYPE: [
        {code: "CA", libelle: "Colonne Aérienne"},
        {code: "CE", libelle: "Colonne Enterrée"},
        {code: "CSE", libelle: "Colonne Semi-enterrée"},
        {code: "AB", libelle: "Abri-bac"},
        {code: "BAC", libelle: "Bac"},
        {code: "AUT", libelle: "Autre"},
    ],
    PROBLEMS_IMPORTANCE: [
        {code: 1, libelle: "Bas", color: "#2ecc71"},
        {code: 2, libelle: "Moyen", color: "#f1c40f"},
        {code: 3, libelle: "Haut", color: "#e67e22"},
        {code: 4, libelle: "Critique", color: "#B22222"},
    ],
    CONTENEURS_STATUS_TODO: 1,
    CONTENEURS_STATUS_DONE: 0,
    CONTENEURS_STATUS_INACCESSIBLE: 3,
    CONTENEURS_STATUS_PROBLEME: 2,

    /** MAP **/
    MAP_RADIUS: 0.0003,
    DEGREES_PER_RADIAN: 180 / Math.PI,
    HERE_MAPS_APIKEY: "MuhS6u_L01hoP99vEg4KOZPmqfatW-t9GTPZOT_WHnQ",
    MODE_MAP: "map",
    MODE_LIST: "list",

    /** CSV MISC **/
    EXPECTED_PATRIMOINE_HEADER:["Nomenclature", "Adresse", "Complement d'adresse", "Commune", "Type de conteneur", "Marque", "Quantite", "Type de dechet", "Volume", "Latitude", "Longitude"],
    EXPECTED_UPDATE_TOURNEE_HEADER:["Nomenclature", "Adresse"],

    /** SYNTHESE **/
    BILAN_TYPE_GLOBAL: "global",
    BILAN_TYPE_BY_PATRIMOINE: "patrimoine",

    /** TYPOLOGIE LAVAGE **/
    TYPOLOGIE_LAVAGE_AB_PLUS_B: "ABRI BAC + BAC",
    TYPOLOGIE_LAVAGE_AB: "ABRI BAC",
    TYPOLOGIE_LAVAGE_B: "BAC",
    TYPOLOGIE_LAVAGE_C: "COLONNE",

}

export default Constants;