import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {usersActions} from "../../modules/users/users.actions";
import Loader from "./layout/loading/loader";
import {Formik, isString} from "formik";
import './UsersNewOrEdit.css'
import {object, string} from 'yup';
import {Container, Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SubmitButton from "./layout/forms/SubmitButton";
import {laveusesActions} from "../../modules/laveuses/laveuses.actions";
import LaveuseDataField from "./laveuse/form/LaveuseDataField";
import {LocalShipping} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";


const laveuseValidationSchema = object({
    immatriculation: string()
        .required("L'immatriculation est requise")
        .max(255, 'Le libellé ne doit pas dépasser 255 caractères'),
});


function LaveusesNewOrEdit({...props}) {
    const dispatch = useDispatch();
    const params = useParams();
    const [mode, setMode] = useState("create");
    const [laveuseId, setLaveuseId] = useState(null);

    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setMode("edit");
            setLaveuseId(params.id);
        } else {
            setMode("create");
            setLaveuseId(null);
        }
    }, [params]);

    useEffect(() => {
        if (laveuseId && (props.laveuse?.id !== parseInt(laveuseId)) && !props.loading && !props.error) {
            dispatch(laveusesActions.fetchLaveuse(laveuseId));
        }
    }, [props, dispatch, laveuseId]);

    useEffect(() => {
        if (!props.users && !props.loading && !props.error) {
            dispatch(usersActions.fetchUsers());
        }
    }, [dispatch, props.users, props.error, props.loading]);

    const getOperateurs = () => {
        if (props.users) {
            // On récupère les opérateurs de l'exploitant connecté
            return props.users[0]?.operateurs
        }
        return []
    }

    const initialValues = {
        id: props.laveuse?.id ?? null,
        immatriculation: props.laveuse?.immatriculation || '',
        description: props.laveuse?.description || '',
        quartixId: props.laveuse?.quartixId || '',
        emissionCo2: props.laveuse?.emissionCo2 || 0,
        operateur: props.laveuse?.operateur?.["@id"] || ''
    };

    const handleSubmit = (values) => {
        if (values.quartixId) {
            values.quartixId = parseInt(values.quartixId)
        }
        if (isString(values.emissionCo2)) {
            values.emissionCo2 = parseInt(values.emissionCo2)
        }

        if (values.operateur === "") {
            delete values.operateur
        }
        if (mode === "create") {
            dispatch(laveusesActions.createLaveuse(values));
        } else {
            dispatch(laveusesActions.updateLaveuse(values, values.id));
        }
    }


    if (props.loading) {
        return <Loader/>;
    }

    return (
        <>
            <PageTitle title={mode === "create" ? "Nouvelle laveuse" : "Modifier la laveuse"} icon={<LocalShipping/>}/>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={laveuseValidationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    handleSubmit(values)
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <LaveuseDataField values={values} errors={errors} handleChange={handleChange}
                                          operateurs={getOperateurs()}
                                          handleBlur={handleBlur}/>

                        <Grid container justifyContent="center" style={{marginTop: '2rem'}}>
                            <Grid item xs={4}>
                                <SubmitButton submitting={isSubmitting && !props.error}/>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        laveuse: state.laveuses.laveuse,
        users: state.users.users,
        loading: state.laveuses.loading || state.users.loading,
        error: state.laveuses.error
    }
}

export default connect(mapStateToProps)(LaveusesNewOrEdit);
